import React, {Component} from "react";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {withLocalize, Translate} from "react-localize-redux";

class Route extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: true,
            showClassName: 'close-panel'
        };
        this.onDragEnd = this.onDragEnd.bind(this);
        this.showPanel = this.showPanel.bind(this);
    }

    onDragEnd(result) {
        const {destination, source, reason} = result;

        if (!destination || reason === 'CANCEL') {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const features = Object.assign([], this.props.features);
        const droppedFeature = this.props.features[source.index];

        this.props.features.splice(source.index, 1);
        this.props.features.splice(destination.index, 0, droppedFeature);

        this.props.funcDeleteRoute();
    }

    showPanel() {
        this.setState({
            show: !this.state.show,
            showClassName: this.state.show ? 'open-panel' : 'close-panel'
        })
    }

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <div className="route-div ol-control">
                    <h6 style={{marginLeft: '0.5em', marginTop: '0.5em'}}><Translate id='iWillVisit'/></h6>
                    <button onClick={this.showPanel} className={this.state.showClassName}/>
                    <hr style={{marginBottom: 'unset', marginTop: 'unset'}}/>
                    {
                        this.state.show ?
                            <div>
                                <Droppable droppableId='dp1'>
                                    {(provided) => (
                                        <div ref={provided.innerRef}{...provided.droppableProps} style={{maxHeight: '40em', overflowY: 'auto', overflowX: 'hidden'}}>
                                            {this.props.features.map((c, index) =>
                                                <Draggable
                                                    key={index}
                                                    draggableId={index + ''}
                                                    index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className="place">
                                                                <div style={{
                                                                    marginTop: '-0.15em',
                                                                    marginRight: '1em',
                                                                    fontSize: '14px',
                                                                    fontWeight: 'bold',
                                                                }}>{index + 1}</div>
                                                                <h6 className="modal-title"
                                                                    style={{fontSize: '0.8rem'}}>{c.properties['name_' + this.props.lang]}</h6>
                                                                <button type="button"
                                                                        className="close"
                                                                        aria-label="Close"
                                                                        style={{
                                                                            backgroundColor: "unset",
                                                                            // position: 'absolute',
                                                                            right: '1rem'
                                                                        }}
                                                                        onClick={this.props.funcDelete}>
                                                                    <span id={c.id} aria-hidden="true">×</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>)
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                {
                                    this.props.routeDistance && this.props.routeDuration ?
                                        <span style={{fontSize: '12px'}}><Translate id='distance'/>: {this.props.routeDistance}km - <Translate id='duration'/>: {this.props.routeDuration}min</span>
                                        : ''
                                }
                                <hr style={{marginBottom: 'unset', marginTop: 'unset'}}/>
                                <div style={{width: '100%'}}>
                                    <button type="button"
                                            className="route-create-button"
                                            onClick={this.props.funcRoute}
                                    ><Translate id='makeRoute'/>
                                    </button>
                                    <button type="button"
                                            className="route-delete-button"
                                            onClick={this.props.funcDeleteRoute}
                                    ><Translate id='deleteRoute'/>
                                    </button>
                                </div>
                            </div>
                            :
                            ''
                    }

                </div>
            </DragDropContext>
        )
    }
}

export default withLocalize(Route);
