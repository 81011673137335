import React, {Component} from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import {BrowserRouter as Router, Route} from "react-router-dom";
import { withLocalize} from "react-localize-redux";
import globalTranslations from "./translations/global.json";
import './css/bootstrap.min.css';
import './css/index.css';
// import Header from './layouts/common/header';
// import Footer from './layouts/common/footer';
// import Home from './layouts/home';
import Greece from './layouts/greece/greece';
// import Albania from './layouts/greece/greece';
// import About from './layouts/about/about';

class Routes extends Component {

  constructor(props) {
    super(props);
    this.props.initialize({
      languages: [
        {
          name: "English",
          code: "en"
        },  {
          name: "Albanian",
          code: "al"
        }, {
          name: "Greek",
          code: "gr"
        }
      ],
      defaultLanguage: 'gr',
      translation: globalTranslations,
      options: {
        defaultLanguage: "gr",
        renderToStaticMarkup
      }
    });
  }

  render() {
    return (
        <Router>
          <div>
            {/*<Header/>*/}
            <Route exact path="/" component={Greece}/>
            {/*<Route path="/Greece" component={Greece}/>*/}
            {/*<Route path="/Albania" component={Albania}/>*/}
            {/*<Route path="/About" component={About}/>*/}
            {/*<Footer/>*/}
          </div>
        </Router>
    );
  }
}

export default withLocalize(Routes);
