import React from 'react';
import {withLocalize} from 'react-localize-redux';
import EN from "../../img/en.svg";
import AL from "../../img/al.svg";
import EL from "../../img/el.svg";

const ChangeLanguage = ({activeLanguage, setActiveLanguage}) => {
    const getClass = (languageCode) => {
        return languageCode === activeLanguage.code ? 'active' : ''
    };

    return (
        <div align="right" style={{
            width: '10em',
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1
        }}>
            <button onClick={() => setActiveLanguage("gr")} style={{borderWidth: "0", backgroundColor: "transparent"}}>
                <img src={EL} alt="el" height="20" border="0" style={{borderColor: "darkgray", borderStyle: "solid"}}/>
            </button>
            <button onClick={() => setActiveLanguage("al")} style={{borderWidth: "0", backgroundColor: "transparent"}}>
                <img src={AL} alt="al" height="20" border="1" style={{borderColor: "darkgray", borderStyle: "solid"}}/>
            </button>
            <button onClick={() => setActiveLanguage("en")} style={{borderWidth: "0", backgroundColor: "transparent"}}>
                <img src={EN} alt="en" height="20" border="0" style={{borderColor: "darkgray", borderStyle: "solid"}}/>
            </button>
        </div>
    );
};

export default withLocalize(ChangeLanguage);
