import {Control} from 'ol/control.js';

let ol_loading_progress = (function (Control) {
    function LoadingProgressControl(opt_options) {
        let options = opt_options || {};

        let div = document.createElement('div');
        div.className = 'ol-progress';

        ol_loading_progress.id = 'ol-progress-bar-' + Math.floor(Math.random() * 1000000);

        let element = document.createElement('div');
        element.setAttribute("id", ol_loading_progress.id);
        element.className = 'progress-bar progress-bar-info';

        div.appendChild(element);

        Control.call(this, {
            element: div,
            target: options.target
        });
    }

    if (Control) LoadingProgressControl.__proto__ = Control;
    LoadingProgressControl.prototype = Object.create(Control && Control.prototype);
    LoadingProgressControl.prototype.constructor = LoadingProgressControl;


    return LoadingProgressControl;
}(Control));

ol_loading_progress.prototype.set = function () {
    const map = this.getMap();
    let progressBar = document.getElementById(ol_loading_progress.id);
    const layers = map.getLayers();
    layers.forEach(function (item) {
        try{
            let tilesLoaded = 0;
            let tilesPending = 0;
            let source = item.getSource();
            source.on(['tileloadend', 'tileloaderror'], function () {
                ++tilesLoaded;
                let percentage = Math.round(tilesLoaded / tilesPending * 100);
                progressBar.style.width = percentage + '%';

                if (percentage >= 100) {
                    setTimeout(function () {
                        progressBar.parentNode.style.opacity = 0;
                        progressBar.style.width = 0;
                        tilesLoaded = 0;
                        tilesPending = 0;
                    }, 600);
                }
            });

            source.on('tileloadstart', function() {
                progressBar.parentNode.style.opacity = 1;
                ++tilesPending;
            });
        }catch (e) {

        }

    })


};

export default ol_loading_progress
