import TileLayer from 'ol/layer/Tile';
// import TileWMS from 'ol/source/TileWMS';
import XYZ from 'ol/source/XYZ';
import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import Stamen from 'ol/source/Stamen';
import OSM from 'ol/source/OSM';
// import BingMaps from 'ol/source/BingMaps';
// import {withLocalize, Translate} from "react-localize-redux";

const layers = [

    //Basemaps
    new TileLayer({
        id: "ESRI_World_Imagery",
        title: "stamenWatercolor",
        baseLayer: true,
        visible: false,
        source: new XYZ({attributions:
                'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer">ArcGIS</a>',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        }),
    }),
    new TileLayer({
        id: "ESRI_World_Street_Map",
        title: "stamenWatercolor",
        baseLayer: true,
        visible: true,
        source: new XYZ({attributions:
                'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer">ArcGIS</a>',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
        }),
    }),
    new TileLayer({
        id: "ESRI_World_Topo_Map",
        title: "stamenWatercolor",
        baseLayer: true,
        visible: false,
        source: new XYZ({attributions:
                'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
            url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}'
        }),
    }),
    // new TileLayer({
    //     id: "osm",
    //     title: "stamenWatercolor",
    //     baseLayer: true,
    //     visible: true,
    //     source: new OSM()
    // }),
    // new TileLayer({
    //     id: "stamenTerrain",
    //     title: "stamenTerrain",
    //     baseLayer: true,
    //     visible: false,
    //     source: new Stamen({
    //         layer: 'terrain',
    //     })
    // }),
    // new TileLayer({
    //     id: "stamenToner",
    //     title: "stamenToner",
    //     baseLayer: true,
    //     visible: false,
    //     source: new Stamen({
    //         layer: 'toner',
    //     })
    // }),
    // new TileLayer({
    //     id: "ESRI_World_Street_Map",
    //     title: "stamenToner",
    //     baseLayer: true,
    //     visible: true,
    //     source: new XYZ({
    //         attributions:
    //             'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
    //             'rest/services/World_Topo_Map/MapServer">ESRI</a>',
    //         url:
    //             'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
    //     }),
    // }),
    // new TileLayer({
    //     id: "ESRI_World_Imagery",
    //     title: "stamenToner",
    //     baseLayer: true,
    //     visible: false,
    //     source: new XYZ({
    //         attributions:
    //             'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
    //             'rest/services/World_Topo_Map/MapServer">ESRI</a>',
    //         url:
    //             'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    //     }),
    // }),
    // new TileLayer({
    //     id: "stamenWatercolor",
    //     title: "stamenWatercolor",
    //     baseLayer: true,
    //     visible: false,
    //     source: new Stamen({
    //         layer: 'watercolor',
    //     })
    // }),


    //Layers
    new ImageLayer({
        id: "dimos",
        title: "dimos",
        visible: true,
        baseLayer: false,
        info: false,
        extent: [
            2278240.298, 4797530.635, 2325937.004, 4844768.718
        ],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER,
            params: {
                'LAYERS': 'dimos',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "dimotikes_enotites",
        title: "dimotikes_enotites",
        visible: true,
        baseLayer: false,
        info: false,
        extent: [
            2278240.298, 4797530.635, 2325937.004, 4844768.718
        ],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER,
            params: {
                'LAYERS': 'dimotikes_enotites',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "ancient",
        title: "ancient",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [
            2278240.298, 4797530.635, 2325937.004, 4844768.718
        ],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER,
            params: {
                'LAYERS': 'ancient',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "bridges",
        title: "bridges",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [
            2278240.298, 4797530.635, 2325937.004, 4844768.718
        ],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER,
            params: {
                'LAYERS': 'bridges',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "churches",
        title: "churches",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [
            2278240.298, 4797530.635, 2325937.004, 4844768.718
        ],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER,
            params: {
                'LAYERS': 'churches',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "preservable_buildings",
        title: "preservable_buildings",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [
            2278240.298, 4797530.635, 2325937.004, 4844768.718
        ],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER,
            params: {
                'LAYERS': 'preservable_buildings',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    }),
    new ImageLayer({
        id: "watermills",
        title: "watermills",
        visible: true,
        baseLayer: false,
        info: true,
        extent: [
            2278240.298, 4797530.635, 2325937.004, 4844768.718
        ],
        source: new ImageWMS({
            url: process.env.REACT_APP_GEOSERVER,
            params: {
                'LAYERS': 'watermills',
                'TILED': true
            },
            serverType: 'geoserver',
            transition: 0
        })
    })
];


export default {
    xy: [2302088.651, 4821149.6765],
    zoom: 12,
    extent: [2278240.298, 4797530.635, 2325937.004, 4844768.718],
    extent_limits: [2220294.324, 4709884.246, 2417501.857, 4901894.061],
    layers
}
