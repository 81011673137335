import Draw from "ol/interaction/Draw";

export const addDrawInteraction = (drawTypeSelect, drawSource, map) => {
    const draw = new Draw({
        source: drawSource,
        type: drawTypeSelect,
    });
    map.addInteraction(draw);
    return draw;
};
