import Overlay from "ol/Overlay";
import {LineString, Polygon} from "ol/geom";
import Draw from "ol/interaction/Draw";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import {unByKey} from "ol/Observable";
import {getArea, getLength} from "ol/sphere";

let measureSketch = null;
let measureHelpTooltipElement = null;
let measureHelpTooltip = null;
let measureTooltipElement = null;
let measureTooltip = null;

export const addMeasureInteraction = (measureTypeSelect, measureSource, map) => {
    let type = measureTypeSelect;
    const draw = new Draw({
        source: measureSource,
        type: type,
        style: new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
        }),
    });
    map.addInteraction(draw);

    createMeasureTooltip(map);
    createMeasureHelpTooltip(map);

    let listener;
    draw.on('drawstart', function (evt) {
        // set sketch
        measureSketch = evt.feature;

        /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
        let tooltipCoord = evt.coordinate;

        listener = measureSketch.getGeometry().on('change', function (evt) {
            let geom = evt.target;
            let output;
            if (geom instanceof Polygon) {
                output = measureFormatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
                output = measureFormatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
            }
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
        });
    });

    draw.on('drawend', function () {
        measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
        measureTooltip.setOffset([0, -7]);
        measureSketch = null;
        measureTooltipElement = null;
        createMeasureTooltip(map);
        unByKey(listener);
    });

    return draw
};

/**
 * Format length output.
 * @param {LineString} line The line.
 * @return {string} The formatted length.
 */
const measureFormatLength = (line) => {
    const length = getLength(line);
    let output;
    if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' km';
    } else {
        output = Math.round(length * 100) / 100 + ' m';
    }
    return output;
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const measureFormatArea = (polygon) => {
    let area = getArea(polygon);
    let output;
    if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' km<sup>2</sup>';
    } else {
        output = Math.round(area * 100) / 100 + ' m<sup>2</sup>';
    }
    return output;
};

/**
 * Creates a new help tooltip
 */
const createMeasureHelpTooltip = (map) => {
    if (measureHelpTooltipElement) {
        measureHelpTooltipElement.parentNode.removeChild(measureHelpTooltipElement);
    }
    measureHelpTooltipElement = document.createElement('div');
    measureHelpTooltipElement.className = 'ol-tooltip hidden';
    measureHelpTooltip = new Overlay({
        element: measureHelpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
    });
    map.addOverlay(measureHelpTooltip);
};

/**
 * Creates a new measure tooltip
 */
const createMeasureTooltip = (map) => {
    if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement('div');
    measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
    measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
    });
    map.addOverlay(measureTooltip);
};

