import React, {Component} from 'react';
import {withLocalize, Translate} from "react-localize-redux";
import config from './config';
import Map from 'ol/Map.js';
import View from 'ol/View.js';
import {OverviewMap, ScaleLine, defaults as defaultControls} from 'ol/control';

import {OSM, Vector as VectorSource} from 'ol/source';
import XYZ from 'ol/source/XYZ';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import {toLonLat, fromLonLat} from 'ol/proj';
import MousePosition from 'ol/control/MousePosition';
import {createStringXY} from 'ol/coordinate';
import ZoomAll from '../../ol-custom/zoomAll/ZoomAll';
import LoadingProgress from '../../ol-custom/loadingProgress/LoadingProgress';
import {Circle as CircleStyle, Fill, Stroke, Style, Icon} from 'ol/style';
import BarControl from 'ol-ext/control/Bar';
import ToggleControl from 'ol-ext/control/Toggle';
import ButtonControl from 'ol-ext/control/Button';
import {FlowerSpinner as Spinner} from 'react-epic-spinners'
import LayerSwitcherImage from '../../ol-custom/layerSwitcherImage/LayerSwitcherImage';
import LayerSwitcher from '../../ol-custom/layerSwitcher/LayerSwitcher';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {addDrawInteraction} from '../../ol-custom/draw/Draw';
import {addMeasureInteraction} from '../../ol-custom/measure/Measure';
import {Print} from '../../ol-custom/print/Print';
import Route from '../../ol-custom/route/Route';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    // Row,
    // Col,
    // UncontrolledCollapse,
    Collapse,
    CardBody,
    Card, Row, Col, Alert 
} from 'reactstrap';
import ChangeLanguage from "../common/changeLanguage";
import Feature from "ol/Feature";
// import IntroModal from "../../components/IntroModal";
import MaterialTable from "material-table";
import 'ol/ol.css';
import 'ol-ext/dist/ol-ext.css';
import '../../css/ol-map.css';


class Greece extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ol_map: null,
            ol_map_basemaps_control: null,
            info_results: [],
            places_to_visit: [],
            routeDistance: false,
            routeDuration: false,
            showInfoModal: false,
            showSearchModal: false,
            showIntroModal: true,
            measureTypeSelect: 'None',
            tableData: false,
            collapse: 0,
            cards: [1, 2, 3, 4],
            showAlert: false
        };

        this.doMapInfo = this.doMapInfo.bind(this);
        this.showMapInfo = this.showMapInfo.bind(this);
        this.toggleInfoModal = this.toggleInfoModal.bind(this);
        this.deleteFromPlaces = this.deleteFromPlaces.bind(this);
        this.doRoute = this.doRoute.bind(this);
        this.deleteRoute = this.deleteRoute.bind(this);
        this.addRouteToMap = this.addRouteToMap.bind(this);
        this.styleFunction = this.styleFunction.bind(this);
        this.notify = this.notify.bind(this);
        this.toggleSearchModal = this.toggleSearchModal.bind(this);
        this.toggleSearchPanel = this.toggleSearchPanel.bind(this);
        this.toggleIntroModal = this.toggleIntroModal.bind(this);

        this.routeStops = 0;

        this.draw = null;

        // Draw layer
        this.drawSource = new VectorSource({wrapX: false});
        this.drawVector = new VectorLayer({
            id: 'drawVector',
            baseLayer: false,
            source: this.drawSource,
        });

        // Measure layer
        this.measureSource = new VectorSource();
        this.measureVector = new VectorLayer({
            id: 'measureVector',
            source: this.measureSource,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2,
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: '#ffcc33',
                    }),
                }),
            }),
        });

        // Route layer
        this.routeVector = null;

        this.getTableData();
    }

    getTableData() {
        const url = process.env.REACT_APP_GEOSERVER + '?SERVICE=WFS&version=2.0.0&request=GetFeature&typeNames=gis:monuments&outputFormat=application/json&srsName=EPSG:3857'
        fetch(process.env.REACT_APP_PROXY + url.replace('?', '&')).then(function (response) {
            return response.json();
        }).then((results) => {
            const groupedFeatures = this.groupBy(results.features, 'type_gr');

            groupedFeatures['bridges'] = groupedFeatures['ΓΕΦΥΡΙ'];
            delete groupedFeatures['ΓΕΦΥΡΙ'];
            groupedFeatures['churches'] = groupedFeatures['ΜΟΝΗ - ΕΚΚΛΗΣΙΑ'];
            delete groupedFeatures['ΜΟΝΗ - ΕΚΚΛΗΣΙΑ'];
            groupedFeatures['watermills'] = groupedFeatures['ΝΕΡΟΜΥΛΟΣ'];
            delete groupedFeatures['ΝΕΡΟΜΥΛΟΣ'];
            groupedFeatures['preservable_buildings'] = groupedFeatures['ΔΙΑΤΗΡΗΤΕΟ ΚΤΙΡΙΟ'];
            delete groupedFeatures['ΔΙΑΤΗΡΗΤΕΟ ΚΤΙΡΙΟ'];
            groupedFeatures['ancient'] = groupedFeatures['ΜΟΥΣΕΙΟ - ΑΡΧΑΙΑ'];
            delete groupedFeatures['ΜΟΥΣΕΙΟ - ΑΡΧΑΙΑ'];

            this.setState({
                tableData: groupedFeatures
            })
        });
    }

    groupBy(objectArray, property) {
        return objectArray.reduce((acc, obj) => {
            const key = obj.properties[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(obj);
            return acc;
        }, {});
    }

    componentDidMount() {

        let t = this;

        // Basemap Layer Control
        const ol_map_basemaps_control = new LayerSwitcherImage();

        // Layers Control
        const ol_map_layers_control = new LayerSwitcher({
            extent: true
        });

        // Loading Progress Control
        const ol_map_LoadingProgressControl = new LoadingProgress();

        // Map Info Control
        const mapInfoControl = new ToggleControl(
            {
                title: 'Info',
                html: '<i class="map-info-icon"></i>',
                onToggle: function (e) {
                    if (e) {
                        t.measureSource.clear();
                        let elements = document.getElementsByClassName('ol-overlay-container');
                        while (elements.length > 0) elements[0].remove();
                        t.state.ol_map.removeInteraction(t.draw);
                        if (drawBar.getActive()) {
                            drawBar.toggle()
                        }
                        drawBar.getSubBar().getControls().forEach(function (ctrl) {
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                        if (measureBar.getActive()) {
                            measureBar.toggle()
                        }
                        measureBar.getSubBar().getControls().forEach(function (ctrl) {
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                        ol_map.on('singleclick', t.doMapInfo);
                        ol_map.getViewport().style.cursor = 'pointer';
                    } else {
                        ol_map.un('singleclick', t.doMapInfo);
                        ol_map.getViewport().style.cursor = 'default';
                    }
                }
            });

        // Draw Main Bar Control
        const drawSubButtons = new BarControl(
            {
                toggleOne: true,
                controls:
                    [new ToggleControl(
                        {
                            title: 'Draw Point',
                            html: '<i class="draw-point"></i>',
                            onToggle: function (b) {
                                if (b) {
                                    t.state.ol_map.removeInteraction(t.draw);
                                    t.draw = addDrawInteraction('Point', t.drawSource, t.state.ol_map);
                                } else {
                                    t.state.ol_map.removeInteraction(t.draw);
                                }
                            }
                        }),
                        new ToggleControl(
                            {
                                title: 'Draw Line',
                                html: '<i class="draw-line"></i>',
                                onToggle: function (b) {
                                    if (b) {
                                        t.state.ol_map.removeInteraction(t.draw);
                                        t.draw = addDrawInteraction('LineString', t.drawSource, t.state.ol_map);
                                    } else {
                                        t.state.ol_map.removeInteraction(t.draw);
                                    }
                                }
                            }),
                        new ToggleControl(
                            {
                                title: 'Draw Polygon',
                                html: '<i class="draw-polygon"></i>',
                                onToggle: function (b) {
                                    if (b) {
                                        t.state.ol_map.removeInteraction(t.draw);
                                        t.draw = addDrawInteraction('Polygon', t.drawSource, t.state.ol_map);
                                    } else {
                                        t.state.ol_map.removeInteraction(t.draw);
                                    }
                                }
                            }),
                        new ButtonControl(
                            {
                                title: 'Delete Drawings',
                                html: '<i class="draw-delete"></i>',
                                handleClick: function () {
                                    t.drawSource.clear()
                                }
                            })
                    ]
            });
        const drawBar = new ToggleControl(
            {
                title: 'Draw',
                html: '<i class="draw-draw"></i>',
                bar: drawSubButtons,
                onToggle: function (e) {
                    if (e) {
                        t.measureSource.clear();
                        let elements = document.getElementsByClassName('ol-overlay-container');
                        while (elements.length > 0) elements[0].remove();
                        if (mapInfoControl.getActive()) {
                            mapInfoControl.toggle()
                        }
                        ol_map.un('singleclick', t.doMapInfo);
                        if (measureBar.getActive()) {
                            measureBar.toggle()
                        }
                        if (measureBar.getActive()) {
                            measureBar.toggle()
                        }
                        measureBar.getSubBar().getControls().forEach(function (ctrl) {
                            t.state.ol_map.removeInteraction(t.draw);
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                        ol_map.getViewport().style.cursor = 'default';
                    } else {
                        drawBar.getSubBar().getControls().forEach(function (ctrl) {
                            t.state.ol_map.removeInteraction(t.draw);
                            try {
                                if (ctrl.getActive()) {
                                    ctrl.toggle()
                                }
                            } catch (e) {
                            }
                        });
                    }
                }
            });

        // Measure Bar Control
        const measureSubButtons = new BarControl(
            {
                title: 'Measure',
                toggleOne: true,
                controls:
                    [new ToggleControl(
                        {
                            title: 'Measure Distance',
                            html: '<i class="measure-icon-line"></i>',
                            onToggle: function (b) {
                                t.state.ol_map.removeInteraction(t.draw);
                                t.draw = addMeasureInteraction('LineString', t.measureSource, t.state.ol_map);
                            }
                        }),
                        new ToggleControl(
                            {
                                title: 'Measure Area',
                                html: '<i class="measure-icon-polygon"></i>',
                                onToggle: function (b) {
                                    t.draw = addMeasureInteraction('Polygon', t.measureSource, t.state.ol_map);
                                }
                            })
                    ]
            });
        const measureBar = new ToggleControl({
            title: 'Measure',
            html: '<i class="measure-icon"></i>',
            bar: measureSubButtons,
            onToggle: function (e) {
                if (e) {
                    t.state.ol_map.removeInteraction(t.draw);
                    if (mapInfoControl.getActive()) {
                        mapInfoControl.toggle()
                    }
                    t.state.ol_map.un('singleclick', t.doMapInfo);
                    if (drawBar.getActive()) {
                        drawBar.toggle()
                    }
                    drawBar.getSubBar().getControls().forEach(function (ctrl) {
                        try {
                            if (ctrl.getActive()) {
                                ctrl.toggle()
                            }
                        } catch (e) {
                        }
                    });
                    ol_map.getViewport().style.cursor = 'default';
                } else {
                    measureBar.getSubBar().getControls().forEach(function (ctrl) {
                        t.state.ol_map.removeInteraction(t.draw);
                        try {
                            if (ctrl.getActive()) {
                                ctrl.toggle()
                            }
                        } catch (e) {
                        }
                    });
                    t.measureSource.clear();
                    let elements = document.getElementsByClassName('ol-overlay-container');
                    while (elements.length > 0) elements[0].remove();
                }
            }
        });

        // Main Left Toolbar
        const leftBarControl = new BarControl({
            controls: [
                mapInfoControl,
                new ButtonControl({
                    title: 'Search',
                    html: '<i class="search-poi"></i>',
                    handleClick: function () {
                        t.setState({
                            showSearchModal: !t.state.showSearchModal
                        })
                    }
                }),
                drawBar,
                measureBar,
                new ButtonControl({
                    title: 'Print',
                    html: '<i class="print-icon"></i>',
                    handleClick: function () {
                        t.setState({
                            loading: true,
                        });
                        const doPrint = Print(ol_map, process.env.REACT_APP_PRINT, t.state.routeDistance, t.state.routeDuration, t.props.translate);
                        doPrint.then(res => res.json()).then((json)=>{
                            t.setState({loading: false},(e)=>{
                                const newWin = window.open(json.getURL.replace('http://localhost:8080/geoserver', 'http://geoserver.culturallands.eu/geoserver'));
                                if(!newWin || newWin.closed || typeof newWin.closed=='undefined'){
                                    t.setState({showAlert: true})
                                }
                            });
                            setTimeout(() => {  t.setState({showAlert: false})}, 4000);
                        });
                    }
                }),
                new ZoomAll({
                    xy: config.xy,
                    zoom: config.zoom,
                    extra: -1
                }),
                new ButtonControl({
                    title: 'About',
                    html: '<i class="about"></i>',
                    handleClick: function () {
                        t.setState({
                            showIntroModal: true
                        })
                    }
                })
            ]
        });

        let ol_map = new Map({
            controls: defaultControls().extend([
                ol_map_basemaps_control,
                ol_map_layers_control,
                new ScaleLine({
                    units: "metric",
                    bar: true,
                    steps: 4,
                    text: false,
                    minWidth: 140,
                }),
                new OverviewMap({
                    className: 'ol-overviewmap ol-custom-overviewmap',
                    layers: [
                        new TileLayer({
                            source: new XYZ({
                                url: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
                            }),
                        })],
                    collapseLabel: '\u00BB',
                    label: '\u00AB'
                }),
                new MousePosition({
                    coordinateFormat: createStringXY(4),
                    projection: 'EPSG:4326',
                    className: 'custom-mouse-position',
                    undefinedHTML: '&nbsp;'
                }),
                ol_map_LoadingProgressControl
            ]),
            layers: config.layers,
            target: 'map_div',
            view: new View({
                center: config.xy,
                zoom: config.zoom,
                extent: config.extent_limits
            })
        });

        ol_map_LoadingProgressControl.set();

        leftBarControl.setPosition('top-left');
        ol_map.addControl(leftBarControl);

        ol_map.addLayer(t.drawVector);
        ol_map.addLayer(t.measureVector);

        // Reset route stops
        ol_map.on('moveend', function (evt) {
            t.routeStops = 0;
        });

        t.setState({
            ol_map: ol_map,
            ol_map_basemaps_control: ol_map_basemaps_control,
            ol_map_layers_control: ol_map_layers_control,
        });

        setTimeout(function () {
            ol_map.updateSize();
            ol_map.getView().setZoom(ol_map.getView().getZoom() - 1);

            //Set layers translations
            config.layers.forEach(function (item) {
                item.values_.title = t.props.translate(item.get('id'));
            });
            try {
                t.state.ol_map.removeControl(t.state.ol_map_basemaps_control);
                t.state.ol_map.removeControl(t.state.ol_map_layers_control);
                t.state.ol_map_basemaps_control._layers.forEach(function (item) {
                    item.layer.values_.title = t.props.translate(item.layer.values_.id);
                });
                t.state.ol_map_layers_control._layers.forEach(function (item) {
                    item.layer.values_.title = t.props.translate(item.layer.values_.id);
                });
                t.state.ol_map.addControl(t.state.ol_map_basemaps_control);
                t.state.ol_map.addControl(t.state.ol_map_layers_control);
            } catch (e) {
            }
        }, 1000);
    }

    // INFO
    doMapInfo(evt) {

        const t = this;
        let requests = [];
        const layers = evt.map.getLayers();
        const viewResolution = /** @type {number} */ (evt.map.getView().getResolution());

        t.setState({
            loading: true,
            info_results: []
        });

        layers.forEach(function (item) {
            if (!item.get('baseLayer') && item.get('info') && item.get('visible') && item.get('id') !== 'drawVector' && item.get('id') !== 'measureVector' && item.get('id') !== 'routeVector') {
                const url = item.get('source').getFeatureInfoUrl(
                    evt.coordinate,
                    viewResolution,
                    'EPSG:3857',
                    {'INFO_FORMAT': 'application/json'}
                );
                if (url) {
                    const request = fetch(process.env.REACT_APP_PROXY + url.replace('?', '&')).then(function (response) {
                        return response.json();
                    });
                    requests.push(request);
                }
            }
        });

        Promise.all(requests).then((responses) => {
            t.setState({loading: false});
            let r = [];
            responses.forEach((response) => {
                if (response.features.length > 0) {
                    r.push(response)
                }
            });
            t.setState({
                info_results: r
            }, t.showMapInfo);
        });

    }

    showMapInfo() {
        if (this.state.info_results.length > 0) {
            this.toggleInfoModal();
        }

    }

    toggleInfoModal() {
        this.setState({
            showInfoModal: !this.state.showInfoModal
        })
    }

    addToPlaces(e, toggle) {
        this.deleteRoute();
        let isAddedToList = false;
        this.state.places_to_visit.forEach((place) => {
            if (place.id === e.id) {
                isAddedToList = true
            }
        });
        if (!isAddedToList) {
            this.setState({
                places_to_visit: [...this.state.places_to_visit, e]
            });
            if (toggle) {
                this.toggleInfoModal()
            }
        }
    }

    deleteFromPlaces(e) {
        const id = e.target.id;
        let newListPlacesToVisit = this.state.places_to_visit.filter(item => item.id !== id);
        this.deleteRoute();
        this.setState({
            places_to_visit: newListPlacesToVisit
        });

    }

    doRoute() {
        let coordinates = this.state.places_to_visit.map((place) =>
            place.geometry.type === 'MultiPoint' ? toLonLat(place.geometry.coordinates[0], 'EPSG:3857') : toLonLat(place.geometry.coordinates, 'EPSG:3857')
        );

        const data = {coordinates: coordinates};

        this.setState({
            loading: true,
            routeDuration: false,
            routeDistance: false
        });

        const t = this;
        (async () => {
            const rawResponse = await fetch(process.env.REACT_APP_PROXY + process.env.REACT_APP_ROUTE + '&SERVICE=ROUTE', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8',
                    'Content-Type': 'application/json',
                    'Authorization': process.env.REACT_APP_ROUTE_TOKEN
                },
                body: JSON.stringify(data)
            });
            const content = await rawResponse.json();
            if (!content.error) {
                t.addRouteToMap(content);

                t.setState({
                    loading: false,
                    routeDistance: (content.features[0].properties.summary.distance / 1000).toFixed(1),
                    routeDuration: (content.features[0].properties.summary.duration / 60).toFixed(0)
                });

                try {
                    const coordMin = fromLonLat([content.bbox[0], content.bbox[1]], 'EPSG:3857');
                    const coordMax = fromLonLat([content.bbox[2], content.bbox[3]], 'EPSG:3857');
                    const extent = [coordMin[0], coordMin[1], coordMax[0], coordMax[1]];
                    t.state.ol_map.getView().fit(extent, t.state.ol_map.getSize());
                    t.state.ol_map.getView().setZoom(t.state.ol_map.getView().getZoom() - 2);
                } catch (e) {
                    t.setState({loading: false});
                    t.notify(t.props.translate('errorRoute'), 'error');
                }
            } else {
                t.setState({loading: false});
                t.notify(t.props.translate('errorRoute'), 'error');
            }
        })();
    }

    addRouteToMap(geojsonObject) {

        const t = this;
        this.state.ol_map.removeLayer(this.routeVector);
        this.routeStops = 0;

        const features = new GeoJSON().readFeatures(geojsonObject);
        features.forEach(function (feature) {
            feature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
        });
        this.routeVector = new VectorLayer({
            id: 'routeVector',
            source: new VectorSource({
                features: features
            }),
            style: t.styleFunction
        });

        this.state.places_to_visit.forEach((f, index) => {
            t.routeVector.getSource().addFeature(
                new Feature({
                    geometry: new Point(f.geometry.type === 'Point' ? f.geometry.coordinates : f.geometry.coordinates[0]),
                    i: index,
                    size: index % 2 ? 10 : 20,
                    _gx_style: index,
                    title: f.properties['name_' + t.props.activeLanguage.code]
                })
            )
        });

        this.state.ol_map.addLayer(this.routeVector);
    }

    deleteRoute() {
        this.setState({
            routeDistance: false,
            routeDuration: false
        });
        this.state.ol_map.removeLayer(this.routeVector);
        this.routeStops = 0;
    }

    styleFunction(feature) {
        const styles = {
            // linestring
            'LineString': new Style({
                stroke: new Stroke({
                    color: '#fcf75e',
                    width: 6,
                })
            }),
            'Point-1': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_1.png',
                }),
            }),
            'Point-2': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_2.png',
                }),
            }),
            'Point-3': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_3.png',
                }),
            }),
            'Point-4': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_4.png',
                }),
            }),
            'Point-5': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_5.png',
                }),
            }),
            'Point-6': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_6.png',
                }),
            }),
            'Point-7': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_7.png',
                }),
            }),
            'Point-8': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_8.png',
                }),
            }),
            'Point-9': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_9.png',
                }),
            }),
            'Point-10': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_10.png',
                }),
            }),
            'Point-11': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_11.png',
                }),
            }),
            'Point-12': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_12.png',
                }),
            }),
            'Point-13': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_13.png',
                }),
            }),
            'Point-14': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_14.png',
                }),
            }),
            'Point-15': new Style({
                image: new Icon({
                    anchor: [0.5, 46],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: 'data/point_15.png',
                }),
            })
        };
        if (feature.getGeometry().getType() === 'Point') {
            const pointNumber = feature.get('_gx_style') + 1;
            return styles['Point-' + pointNumber];
        } else {
            return styles[feature.getGeometry().getType()];
        }
    };

    notify(message, type) {
        if (type === 'error') {
            toast.error(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    toggleSearchModal() {
        this.setState({
            showSearchModal: !this.state.showSearchModal
        })
    }

    toggleSearchPanel(e) {
        let event = e.target.dataset.event;
        this.setState({collapse: this.state.collapse === Number(event) ? 0 : Number(event)});
    }

    toggleIntroModal() {
        this.setState({
            showIntroModal: !this.state.showIntroModal
        })
    }

    componentDidUpdate(prevProps) {
        let t = this;
        if (prevProps.activeLanguage !== this.props.activeLanguage) {
            try {
                t.state.ol_map.removeControl(t.state.ol_map_basemaps_control);
                t.state.ol_map.removeControl(t.state.ol_map_layers_control);
                t.state.ol_map_basemaps_control._layers.forEach(function (item) {
                    item.layer.values_.title = t.props.translate(item.layer.values_.id);
                });
                t.state.ol_map_layers_control._layers.forEach(function (item) {

                    if (t.props.activeLanguage.code !== 'gr'){
                        item.layer.getSource().updateParams({
                            STYLES: item.layer.values_.id + '_' + t.props.activeLanguage.code
                        })
                    }else{
                        item.layer.getSource().updateParams({
                            STYLES: ''
                        })
                    }



                    // if (t.props.activeLanguage.code === 'en') {
                    //     if (item.layer.values_.id === 'dimotikes_enotites') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'dimotikes_enotites_en'
                    //         })
                    //     } else if (item.layer.values_.id === 'bridges') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'bridges_en'
                    //         })
                    //     } else if (item.layer.values_.id === 'watermills') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'watermills_en'
                    //         })
                    //     } else if (item.layer.values_.id === 'preservable_buildings') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'preservable_buildings_en'
                    //         })
                    //     } else if (item.layer.values_.id === 'churches') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'church_en'
                    //         })
                    //     }
                    // } else if (t.props.activeLanguage.code === 'al') {
                    //     if (item.layer.values_.id === 'dimotikes_enotites') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'dimotikes_enotites_en'
                    //         })
                    //     } else if (item.layer.values_.id === 'bridges') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'bridges_al'
                    //         })
                    //     } else if (item.layer.values_.id === 'watermills') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'watermills_al'
                    //         })
                    //     } else if (item.layer.values_.id === 'preservable_buildings') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'preservable_buildings_al'
                    //         })
                    //     } else if (item.layer.values_.id === 'churches') {
                    //         item.layer.getSource().updateParams({
                    //             STYLES: 'church_al'
                    //         })
                    //     }
                    // } else {
                    //     item.layer.getSource().updateParams({
                    //         STYLES: ''
                    //     })
                    // }
                    item.layer.values_.title = t.props.translate(item.layer.values_.id);
                });
                t.state.ol_map.addControl(t.state.ol_map_basemaps_control);
                t.state.ol_map.addControl(t.state.ol_map_layers_control);
            } catch (e) {
            }
        }
    }

    render() {
        return (<div className="map_parent_div">

            <ChangeLanguage/>

            <div id="map_div" className="map"/>

            {
                this.state.showAlert ?
                <Alert color="danger" style={{
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '20em',
                    top: '0.5em'
                }}>
                    <Translate id='popupsAllow'/>
                </Alert>
                : null
            }

            {
                this.state.places_to_visit.length > 0 ?
                    <Route features={this.state.places_to_visit} funcDelete={this.deleteFromPlaces}
                           funcRoute={this.doRoute} funcDeleteRoute={this.deleteRoute}
                           routeDistance={this.state.routeDistance}
                           routeDuration={this.state.routeDuration}
                           lang={this.props.activeLanguage.code}/>
                    : ''
            }

            <Modal isOpen={this.state.showInfoModal} toggle={this.toggleInfoModal} scrollable={true}>
                <ModalHeader
                    toggle={this.toggleInfoModal}>{this.state.info_results.length > 0 ? this.state.info_results[0].features[0].properties['name_' + this.props.activeLanguage.code] : ''}</ModalHeader>
                <ModalBody>
                    {
                        this.state.info_results.length > 0 && this.state.info_results[0].features[0].properties['photo'] ?
                            this.state.info_results[0].features[0].properties['photo'].split(';').map((photo, index) =>
                                <div key={`div_img_${index}`}><img key={`img_${index}`} src={'photos/' + photo} alt="info-1" style={{width: '100%'}}/><br/><br/></div>
                            )
                            : ''
                    }
                    <hr/>
                    {
                        this.state.info_results.length > 0 && this.state.info_results[0].features[0].properties['video'] ?
                            this.state.info_results[0].features[0].properties['video'].split(';').map((video, index) =>
                                <iframe title='video-iframe' key={`iframe_${index}`} src={video}
                                        style={{width: '100%', height: '30rem'}}/>
                            )
                            : ''
                    }
                    <hr/>
                    {
                        this.state.info_results.length > 0 && this.state.info_results[0].features[0].properties['description_' + this.props.activeLanguage.code] ?
                            this.state.info_results[0].features[0].properties['description_' + this.props.activeLanguage.code].split('</p><p>').map((paragraph, index) =>
                                <p key={`p_${index}`}>{paragraph.replace('<p>', '').replace('</p>', '')}</p>
                            )
                            : ''
                    }
                </ModalBody>
                <ModalFooter>
                    {
                        (this.state.info_results.length > 0 && this.state.places_to_visit.some(place => place.id === this.state.info_results[0].features[0]['id'])) || (this.state.places_to_visit.length > 14) ?
                            <Button disabled={true} color="success"
                                    onClick={() => this.addToPlaces(this.state.info_results[0].features[0], true)}><Translate
                                id="visit"/></Button>
                            :
                            <Button disabled={false} color="success"
                                    onClick={() => this.addToPlaces(this.state.info_results[0].features[0], true)}><Translate
                                id="visit"/></Button>
                    }
                </ModalFooter>
            </Modal>

            <Modal isOpen={this.state.showSearchModal} toggle={this.toggleSearchModal} scrollable={true}>
                <ModalHeader toggle={this.toggleSearchModal}>{this.props.translate('search')}</ModalHeader>
                <ModalBody>{
                    config.layers.map((e, index) => {
                            return !e.get('baseLayer') && e.get('info') ?
                                <div key={index}>
                                    <Button id={"toggler_" + index}
                                            style={{
                                                width: '100%',
                                                marginTop: '0.5em',
                                                marginBottom: '0.5em'
                                            }}
                                            data-event={index}
                                            onClick={this.toggleSearchPanel}
                                    >{e.get('title')}</Button>
                                    <Collapse isOpen={this.state.collapse === index}>
                                        <Card>
                                            <CardBody>
                                                {
                                                    this.state.tableData ?
                                                        <MaterialTable
                                                            title=" "
                                                            columns={[
                                                                {
                                                                    title: this.props.translate('name'),
                                                                    field: 'name_' + this.props.activeLanguage.code
                                                                }
                                                            ]}
                                                            data={
                                                                this.state.tableData[e.get('id')].map((item) => {
                                                                    item.properties.geometry = item.geometry;
                                                                    item.properties.feature = item;
                                                                    return item.properties
                                                                })
                                                            }
                                                            detailPanel={rowData => {
                                                                const img = rowData.photo ? rowData.photo.split(';')[0] : null;
                                                                return (
                                                                    <div style={{display: 'flex'}}>
                                                                        <img
                                                                            style={{margin: '1em'}}
                                                                            width="50%"
                                                                            height="50%"
                                                                            alt="info-2"
                                                                            src={'photos/' + img}
                                                                        />
                                                                        <div style={{margin: '1em'}}>
                                                                            {
                                                                                rowData['description_' + this.props.activeLanguage.code].split('</p><p>').map((paragraph, index) =>
                                                                                    <p key={`p_${index}`}>{paragraph.replace('<p>', '').replace('</p>', '')}</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            }
                                                            options={{
                                                                search: true,
                                                                padding: 0,
                                                                searchFieldStyle: {
                                                                    fontSize: 12,
                                                                },
                                                                rowStyle: {
                                                                    fontSize: 12
                                                                }
                                                            }}
                                                            actions={[
                                                                {
                                                                    icon: 'search',
                                                                    tooltip: this.props.translate('zoomTo'),
                                                                    onClick: (event, rowData) => {
                                                                        try {
                                                                            // const point = fromLonLat(rowData.geometry.coordinates[0], 'EPSG:3857');
                                                                            this.state.ol_map.getView().setCenter(rowData.geometry.coordinates[0]);
                                                                            this.state.ol_map.getView().setZoom(17);
                                                                            this.toggleSearchModal();
                                                                        } catch (e) {

                                                                        }
                                                                    }
                                                                },
                                                                {
                                                                    icon: 'list',
                                                                    tooltip: this.props.translate('visit'),
                                                                    onClick: (event, rowData) => {
                                                                        event.preventDefault()
                                                                        try {
                                                                            if (rowData.feature.geometry === null) {
                                                                                this.setState({loading: false});
                                                                                this.notify(this.props.translate('errorGeometry'), 'error');
                                                                            } else {
                                                                                this.addToPlaces(rowData.feature, false)
                                                                            }
                                                                        } catch (e) {

                                                                        }
                                                                    }
                                                                }
                                                            ]}
                                                            localization={{
                                                                header: {
                                                                    actions: ''
                                                                },
                                                                toolbar: {
                                                                    searchTooltip: this.props.translate('search'),
                                                                    searchPlaceholder: this.props.translate('search')
                                                                },
                                                                pagination: {
                                                                    labelDisplayedRows: '{from}-{to} ' + this.props.translate('from') + ' {count}',
                                                                    labelRowsSelect: this.props.translate('labelRowsSelect'),
                                                                    labelRowsPerPage: this.props.translate('labelRowsPerPage'),
                                                                    firstAriaLabel: this.props.translate('firstAriaLabel'),
                                                                    firstTooltip: this.props.translate('firstAriaLabel'),
                                                                    previousAriaLabel: this.props.translate('previousAriaLabel'),
                                                                    previousTooltip: this.props.translate('previousAriaLabel'),
                                                                    nextAriaLabel: this.props.translate('nextAriaLabel'),
                                                                    nextTooltip: this.props.translate('nextAriaLabel'),
                                                                    lastAriaLabel: this.props.translate('lastAriaLabel'),
                                                                    lastTooltip: this.props.translate('lastAriaLabel')
                                                                }
                                                            }}
                                                        />
                                                        :
                                                        <div/>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </div>
                                : ''
                        }
                    )
                }</ModalBody>
            </Modal>

            {
                this.state.loading ? <Spinner color='#000000' size='100' className='spinner'/> : ''
            }

            {/*<IntroModal show={this.state.showIntroModal}/>*/}

            <Modal isOpen={this.state.showIntroModal} toggle={this.toggleIntroModal} className='intro-modal'
                   scrollable={false}>
                <ModalHeader toggle={this.toggleIntroModal}><Translate id='welcome'/></ModalHeader>
                <ModalBody>
                    <p style={{textAlign: 'center'}}><a
                        href="https://greece-albania.eu/projects/greece-and-albania-joint-initiatives-for-cultural-preservation-through-innovative-actions"
                        target="new"><img
                        src={'img/logo_cultural_lands.png'} alt="intro-1"
                        style={{width: '50%'}}/></a></p>
                    <p style={{textAlign: 'center'}}><Translate id='aboutP1'/></p>
                    <p style={{textAlign: 'center'}}><Translate id='aboutP2'/></p>
                    <hr/>
                    <p style={{textAlgin: 'center'}}>
                        <Row>
                            <Col className="text-center"><a href="https://www.zitsa.gov.gr/" target="new"><img
                                src={'img/logo-zitsa.png'} alt="logo1"
                                style={{width: '15em'}}/></a></Col>
                            <Col className="text-center"><a href="https://dimosmetsovou.gr/" target="new"><img
                                src={'img/logo-metsovo.jpg'} alt="logo2"
                                style={{width: '15em'}}/></a></Col>
                            <Col className="text-center"><a href="https://www.facebook.com/prefektiqarkutgjirokaster"
                                                            target="new"><img src={'img/logo-al.jpg'} alt="logo3"
                                                                              style={{width: '15em'}}/></a></Col>
                        </Row>
                    </p>
                    <hr/>
                    <p style={{textAlign: 'right'}}>Created by <a href="http://www.cartografia.gr" target="new">www.cartografia.gr</a></p>
                    <ChangeLanguage/>
                </ModalBody>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </div>);
    }
}

export default withLocalize(Greece);
