import GeoJSON from 'ol/format/GeoJSON';

export const Print = async (map, print_service_url, routeDistance, routeDuration, translate) => {

    const allowed_scales = [50, 100, 500, 1000, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 20000, 25000, 50000, 75000, 100000, 200000, 50000, 1000000, 2000000, 4000000]

    const center = map.getView().getCenter();
    const layers = map.getLayers().getArray();

    let destinations = [];

    let scale = 39.37 * 72 * map.getView().getResolution();
    scale = Math.round(scale);

    const scale_accepted = allowed_scales.reduce((prev, curr) => Math.abs(curr - scale) < Math.abs(prev - scale) ? curr : prev);

    let layers_for_print = [];

    layers.forEach((ly) => {
        if (ly.get('baseLayer') && ly.get('visible')) {
            let baseURL;
            let type = 'OSM';
            if (ly.get('id') === 'osm') {
                baseURL = 'http://tile.openstreetmap.org'
            } else if (ly.get('id') === 'stamenWatercolor') {
                baseURL = 'http://tile.stamen.com/watercolor'
            } else if (ly.get('id') === 'stamenTerrain') {
                baseURL = 'http://tile.stamen.com/terrain'
            } else if (ly.get('id') === 'stamenToner') {
                baseURL = 'http://tile.stamen.com/toner'
            }
            else if (ly.get('id') === 'ESRI_World_Street_Map') {
                layers_for_print.push({
                    baseURL: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile',
                    singleTile: false,
                    type: 'XYZ',
                    maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                    tileSize: [256, 256],
                    extension: 'png',
                    path_format: '${z}/${y}/${x}',
                    tileOriginCorner:'bl',
                    resolutions: [156543.0339, 78271.51695, 39135.758475, 19567.8792375, 9783.93961875, 4891.969809375, 2445.9849046875, 1222.99245234375, 611.496226171875, 305.7481130859375, 152.87405654296876, 76.43702827148438, 38.21851413574219, 19.109257067871095, 9.554628533935547, 4.777314266967774, 2.388657133483887, 1.1943285667419434, 0.5971642833709717]
                })
            }else if (ly.get('id') === 'ESRI_World_Imagery') {
                layers_for_print.push({
                    baseURL: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile',
                    singleTile: false,
                    type: 'XYZ',
                    maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                    tileSize: [256, 256],
                    extension: 'png',
                    path_format: '${z}/${y}/${x}',
                    tileOriginCorner:'bl',
                    resolutions: [156543.0339, 78271.51695, 39135.758475, 19567.8792375, 9783.93961875, 4891.969809375, 2445.9849046875, 1222.99245234375, 611.496226171875, 305.7481130859375, 152.87405654296876, 76.43702827148438, 38.21851413574219, 19.109257067871095, 9.554628533935547, 4.777314266967774, 2.388657133483887, 1.1943285667419434, 0.5971642833709717]
                })
            }else if (ly.get('id') === 'ESRI_World_Topo_Map') {
                layers_for_print.push({
                    baseURL: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile',
                    singleTile: false,
                    type: 'XYZ',
                    maxExtent: [-20037508.3392, -20037508.3392, 20037508.3392, 20037508.3392],
                    tileSize: [256, 256],
                    extension: 'png',
                    path_format: '${z}/${y}/${x}',
                    tileOriginCorner:'bl',
                    resolutions: [156543.0339, 78271.51695, 39135.758475, 19567.8792375, 9783.93961875, 4891.969809375, 2445.9849046875, 1222.99245234375, 611.496226171875, 305.7481130859375, 152.87405654296876, 76.43702827148438, 38.21851413574219, 19.109257067871095, 9.554628533935547, 4.777314266967774, 2.388657133483887, 1.1943285667419434, 0.5971642833709717]
                })
            }

        }
    });

    layers.forEach((ly) => {

        let ly_style = ''
        if(translate('greece') === 'Greqia'){
            ly_style = ly.values_.id + '_al'
        }else{
            ly_style = ly.values_.id + '_en'
        }


        if (ly.get('id') !== 'drawVector' && ly.get('id') !== 'measureVector' && ly.get('id') !== 'routeVector' && !ly.get('baseLayer') && ly.get('visible')) {
           layers_for_print.push({
                baseURL: ly.get('source').getUrl(),
                singleTile: true,
                layers: [ly.get('source').getParams().LAYERS],
                opacity: 1,
                type: 'WMS',
                format: 'image/png',
                styles: [ly_style]
            })
        }
        if (ly.get('id') === 'routeVector') {
            const geojson = JSON.parse(new GeoJSON().writeFeatures(ly.getSource().getFeatures()));
            // geojson.features[0].properties['_gx_style'] = 15;
            geojson.features.forEach((e)=>{
                if(e.geometry.type === 'LineString'){
                    e.properties['_gx_style'] = 15;
                }
            });
            layers_for_print.push({
                type: 'Vector',
                opacity: '1.0',
                styles: {
                    "0":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_1.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "1":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_2.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "2":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_3.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "3":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_4.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "4":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_5.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "5":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_6.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "6":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_7.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "7":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_8.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "8":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_9.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "9":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_10.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "10":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_11.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "11":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_12.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "12":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_13.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "13":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_14.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "14":
                        {
                            "externalGraphic": process.env.REACT_APP_DATA + "/point_15.png",
                            "graphicWidth": 31,
                            "graphicHeight": 31,
                            "graphicYOffset": -30
                        },
                    "15":
                        {
                            "strokeColor": "#fcf75e",
                            "strokeWidth": 3,
                            "pointRadius": 6,
                            "pointerEvents": "visiblePainted"
                        }
                },
                styleProperty: '_gx_style',
                name: 'route',
                geoJson: geojson
            });
            geojson.features.forEach((f)=>{
                if(f.geometry.type === 'Point'){
                    destinations.push(f.properties['title']);
                }
            });
        }
    });

    const routeDetails = routeDistance && routeDuration ? translate('distance') + ':' + routeDistance + 'km - ' + translate('duration') + ':' + routeDuration +'min' : '';

    const print_config = {
        units: "meters",
        srs: "EPSG:900913",
        geodetic: true,
        layout: "A4 portrait",
        dpi: 300,
        mapTitle: translate('mapTitle'),
        routeDetails: routeDetails,
        routeDestination1: destinations[0] ? "1: " + destinations[0] : '',
        routeDestination2: destinations[1] ? "2: " + destinations[1] : '',
        routeDestination3: destinations[2] ? "3: " + destinations[2] : '',
        routeDestination4: destinations[3] ? "4: " + destinations[3] : '',
        routeDestination5: destinations[4] ? "5: " + destinations[4] : '',
        routeDestination6: destinations[5] ? "6: " + destinations[5] : '',
        routeDestination7: destinations[6] ? "7: " + destinations[6] : '',
        routeDestination8: destinations[7] ? "8: " + destinations[7] : '',
        routeDestination9: destinations[8] ? "9: " + destinations[8] : '',
        routeDestination10: destinations[9] ? "10: " + destinations[9] : '',
        routeDestination11: destinations[10] ? "11: " + destinations[10] : '',
        routeDestination12: destinations[11] ? "12: " + destinations[11] : '',
        routeDestination13: destinations[12] ? "13: " + destinations[12] : '',
        routeDestination14: destinations[13] ? "14: " + destinations[13] : '',
        routeDestination15: destinations[14] ? "15: " + destinations[14] : '',
        resourcesUrl: "http://localhost:8080/img",
        layers: layers_for_print,
        pages: [{
            center: center,
            geodetic: true,
            strictEpsg4326: true,
            scale: scale_accepted,
            rotation: 0
        }],
        legends: []
    };

    return await fetch(process.env.REACT_APP_PROXY + print_service_url + '&SERVICE=PRINT', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(print_config)
        });

    // (async () => {
    //     const rawResponse = await fetch(process.env.REACT_APP_PROXY + print_service_url + '&SERVICE=PRINT', {
    //         method: 'POST',
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify(print_config)
    //     });
    //     const content = await rawResponse.json();
    //     window.open(content.getURL.replace('http://localhost:8080/geoserver', 'http://geoserver.culturallands.eu/geoserver'));
    // })();

};
